import Tagify from '@yaireo/tagify';
import flatpickr from 'flatpickr';

class BaseView {
    _invoiceForm = document.querySelector('#invoiceForm');
    _emailsInput = document.getElementById('emails');
    _dateFromInput = document.getElementById('dateFrom');
    _dateToInput = document.getElementById('dateTo');
    _rightPane = document.querySelector('.right-pane');
    _submitBtn = document.querySelector('#submit');

    constructor() {
        this._tagifyEmailsInput();
        flatpickr(this._dateFromInput, { allowInput: true });
        flatpickr(this._dateToInput, { allowInput: true });
    }

    addHandlerNewInvoice(handler) {
        this._invoiceForm.addEventListener('submit', function (e) {
            e.preventDefault();
            if (!this.checkValidity()) {
                this.classList.add('was-validated');
                return false;
            }

            const formElements = this.elements;
            const invoiceData = {
                dateFrom: new Date(formElements.dateFrom.value),
                dateTo: new Date(formElements.dateTo.value),
                emails: formElements.emails.value.split(','),
                businessName: formElements.businessName.value,
                businessAddress: formElements.businessAddress.value,
            };

            handler(invoiceData);
        });
    }

    renderRightPaneMessage(message = 'Nothing to show yet :(', type = 'notice') {
        const markup = `
            <div class="d-flex align-items-center justify-content-center h-100">
                <div class="d-flex flex-column align-items-center">
                    <div class="alert rounded-pill mt-3 px-4 py-2 ${
                        type == 'alert' ? 'alert-danger' : 'alert-secondary'
                    }">${message}</div>
                </div>
            </div>
        `;

        this._rightPane.innerHTML = markup;
    }

    renderError(error) {
        this.renderRightPaneMessage(error.message, 'alert');
        this.enableFormSubmit();
    }

    enableFormSubmit() {
        this._submitBtn.disabled = false;
    }

    disableFormSubmit() {
        this._submitBtn.disabled = true;
    }

    renderSpinner(message) {
        this._rightPane.innerHTML = '';
        const markup = `
            <div class="d-flex align-items-center justify-content-center h-100">
                <div class="container d-flex flex-column align-items-center">
                    <span class="loader"></span>
                    <p class="mt-3 spinner-message">${message}</p>
                </div>
            </div>
        `;
        this._rightPane.insertAdjacentHTML('afterbegin', markup);
    }

    _tagifyEmailsInput() {
        new Tagify(this._emailsInput, {
            originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
            // email address validation (https://stackoverflow.com/a/46181/104380)
            pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        });
    }
}

export default new BaseView();
