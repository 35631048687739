import * as model from './model';
import baseView from './views/baseView';
import invoiceView from './views/invoiceView';
import { inject } from '@vercel/analytics';

const controlNewInvoice = async function (invoiceData) {
    try {
        baseView.disableFormSubmit();
        if ((invoiceData.dateTo - invoiceData.dateFrom) / (1000 * 3600 * 24) > 31)
            throw new Error('Invoice period is limited to maximum 31 days. Try again.');

        if ((invoiceData.dateTo - invoiceData.dateFrom) / (1000 * 3600 * 24) < 0)
            throw new Error('Invoice period cannot be negative. Try again.');

        baseView.renderSpinner('Generating invoice. It may take a while.');

        await model.createInvoice(invoiceData);
        if (!model.state.invoice.items.length) throw new Error('No orders found. Please try again.');

        invoiceView.renderInvoice(model.state.invoice);
        baseView.enableFormSubmit();
    } catch (error) {
        baseView.renderError(error);
    }
};

const init = function () {
    // baseView.renderSpinner('Generating invoice. It may take a while.');
    baseView.renderRightPaneMessage();
    baseView.addHandlerNewInvoice(controlNewInvoice);
    inject();
};

init();
