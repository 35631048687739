import 'core-js/stable';
import { getAllOrders, getCoupon, getOrder } from './helpers';

export const state = {
    invoice: {},
};

export const createInvoice = async function (invoiceData) {
    try {
        state.invoice = invoiceData;
        state.invoice.items = [];
        await loadInvoiceItems();
        state.invoice.id = generateInvoiceId();
        state.invoice.total = calcTotal();
    } catch (error) {
        throw error;
    }
};

const loadInvoiceItems = async function () {
    try {
        const allOrders = await getAllOrders();

        const filteredOrders = allOrders.filter(order => {
            const tsDateFrom = +state.invoice.dateFrom / 1000;
            const tsDateTo = +state.invoice.dateTo / 1000;
            return (
                order.status === 'COMPLETED' &&
                order.created_at > tsDateFrom &&
                order.created_at < tsDateTo &&
                state.invoice.emails.includes(order.customer_email)
            );
        });

        const items = {};
        for (const order of filteredOrders) {
            if (order.type === 'PRODUCT') {
                if (items[order.product_title] == null) {
                    items[order.product_title] = {
                        quantity: [order.quantity],
                        unitPrice: [order.total / order.quantity],
                    };
                } else {
                    items[order.product_title].quantity.push(order.quantity);
                    items[order.product_title].unitPrice.push(order.total / order.quantity);
                }
            } else if (order.type === 'SHOPPING_CART') {
                const fullOrderObj = await getOrder(order.uniqid);
                let discount = 0;
                if (fullOrderObj.coupon_id) {
                    const coupon = await getCoupon(fullOrderObj.coupon_id);
                    discount = coupon.discount;
                }
                Object.values(fullOrderObj.purchase_info).forEach(item => {
                    if (items[item.product.title] == null) {
                        items[item.product.title] = {
                            quantity: [item.unit_quantity],
                            unitPrice: [(+item.unit_price * (100 - discount)) / 100],
                        };
                    } else {
                        items[item.product.title].quantity.push(item.unit_quantity);
                        items[item.product.title].unitPrice.push((+item.unit_price * (100 - discount)) / 100);
                    }
                });
            }
        }

        state.invoice.items = Object.entries(items).map(item => {
            return {
                title: item[0],
                quantity: item[1].quantity.reduce((acc, curr) => acc + curr),
                unitPrice: item[1].unitPrice.reduce((acc, curr) => acc + curr) / item[1].unitPrice.length,
            };
        });
    } catch (error) {
        throw error;
    }
};

const calcTotal = function () {
    return state.invoice.items.reduce((acc, item) => acc + item.quantity * item.unitPrice, 0).toFixed(2);
};

const generateInvoiceId = function () {
    const date = new Date();
    return `${date.getFullYear().toString().substring(2)}${date.getMonth()}${Math.floor(Math.random() * 1000)}`;
};
