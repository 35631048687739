import logo from '../../static/logo.png';
import { jsPDF } from 'jspdf';

class InvoiceView {
    _parentElement = document.querySelector('.right-pane');

    renderInvoice(invoice) {
        this._parentElement.innerHTML = '';

        const markup = `
            <div class="card font-monospace" id="invoice">
                <div class="card-body">
                    <div class="row align-items-center border-bottom">
                        <div class="col-sm-6 text-md-start">
                            <img src="${logo}" alt="logo" style="width: 100px" />
                            <h5>Christy Software Solutions Ltd</h5>
                        </div>
                        <div class="col-sm-6 text-md-end">
                            <h1>INVOICE</h1>
                            <p>#${invoice.id}</p>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row mt-3">
                        <div class="col-sm-6">
                            <ul class="list list-unstyled text-start">
                                <li class="text-muted">Bill to:</li>
                                <li class="mt-2"><h6 class="m-0 fw-bold">${invoice.businessName}</h6></li>
                                <li> ${invoice.businessAddress
                                    .split('\n')
                                    .map(line => `<li>${line}</li>`)
                                    .join('')}
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-flex flex-wrap justify-content-between">
                                <ul class="list list-unstyled text-start mx-sm-auto">
                                    <li class="text-muted">Date:</li>
                                    <li class="text-muted">Payment terms:</li>
                                    <li class="text-muted">Due date:</li>
                                    <li class="text-muted my-2">
                                        <h6 class="m-0 fw-bold">Balance due:</h6>
                                    </li>
                                </ul>
                                <ul class="list list-unstyled text-end">
                                    <li>${invoice.dateFrom.toLocaleDateString('en-UK')}</li>
                                    <li>PIA</li>
                                    <li>${invoice.dateTo.toLocaleDateString('en-UK')}</li>
                                    <li class="my-2">
                                        <h6 class="m-0 fw-bold">$0.00</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="card-body table-responsive-sm py-0">
                    <table class="table">
                        <thead class="border-bottom border-dark">
                            <th class="col-8 text-start">Item</th>
                            <th class="col-1">Quantity</th>
                            <th class="col text-end">Rate</th>
                            <th class="col text-end">Amount</th>
                        </thead>
                        <tbody>
                        ${invoice.items
                            .sort((a, b) => a.date - b.date)
                            .map(item => {
                                return `
                                    <tr>
                                        <td class="text-start">${item.title}</td>
                                        <td>${item.quantity}</td>
                                        <td class="text-end">${item.unitPrice.toFixed(2)}</td>
                                        <td class="text-end">${(item.quantity * item.unitPrice).toFixed(2)}</td>
                                    </tr>
                                `;
                            })
                            .join('')}
                        </tbody>
                    </table>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-md-end justify-content-sm-center">
                        <div class="row col-12 col-lg-6 justify-content-between">
                            <ul class="col list list-unstyled">
                                <li class="text-end"><h5>Total:</h5></li>
                                <li class="text-end">Amount paid:</li>
                            </ul>
                            <ul class="col list list-unstyled">
                                <li class="text-end"><h5>$${invoice.total}</h5></li>
                                <li class="text-end">$${invoice.total}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay py-3 text-center position-sticky sticky-bottom">
                <button class="btn btn-dark btn-lg" id="download">Download PDF</button>
            </div>
        `;

        this._parentElement.insertAdjacentHTML('afterbegin', markup);
        this.downloadInvoicePDF(invoice.id);
    }

    downloadInvoicePDF(id) {
        const btn = document.querySelector('#download');
        const invoiceHTML = document.getElementById('invoice');
        if (!btn || !invoiceHTML) return;

        btn.addEventListener('click', () => {
            console.log('called');
            const pdf = new jsPDF(['p', 'pt', 'a4']);

            pdf.html(invoiceHTML, {
                callback: function (doc) {
                    doc.save(`invoice_${id}`);
                },
                autoPaging: 'text',
                width: 210,
                windowWidth: 1024,
            });
        });
    }
}

export default new InvoiceView();
