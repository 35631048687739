import { SELLIX_API_BASE_URL } from './config';

export const getAllOrders = async function () {
    try {
        const url = `${SELLIX_API_BASE_URL}/orders`;

        await asyncDelay(5000);
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.SELLIX_API_KEY}`,
                'Content-Type': 'application/json',
            },
        });

        const {
            data: { orders },
        } = await res.json();

        return orders;
    } catch (error) {
        throw error;
    }
};

export const getOrder = async function (id) {
    try {
        const url = `${SELLIX_API_BASE_URL}/orders/${id}`;

        await asyncDelay(5000);
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.SELLIX_API_KEY}`,
                'Content-Type': 'application/json',
            },
        });

        const {
            data: { order },
        } = await res.json();

        return order;
    } catch (error) {
        throw error;
    }
};

export const getCoupon = async function (id) {
    try {
        const url = `${SELLIX_API_BASE_URL}/coupons/${id}`;

        await asyncDelay(5000);
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.SELLIX_API_KEY}`,
                'Content-Type': 'application/json',
            },
        });

        const {
            data: { coupon },
        } = await res.json();

        return coupon;
    } catch (error) {
        throw error;
    }
};

const asyncDelay = function (milliseconds) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
};
